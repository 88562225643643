.bk-header {
    width: 100%;
    background-color: $secondary-color;  
    text-transform: uppercase;     
    line-height: 1.2;
    transition: 0.4s;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    padding: 0.5rem 0rem;

    &.fixed {
        .header-bk {
            position: fixed;
        }
    }

    &.active {
        left: -280px;

        @include mq(xl) {
            left: 0;
        }
    }

    a {
        transition: 0.4s;        

        &:not(.btn):not(.obj):not(.button) {
            &::after {
                display: none;
            }
        }
    }

    nav {
        a {
            color: $white;
            font-weight: 400;    
            letter-spacing: 2px;   
            padding: 0.9rem 1.2rem;   
            font-size: 1rem;  

            &:hover {
                color: $primary-color;
            }
        }

        .order-btn{
            background-color: $primary-color;
            color: $primary-font-color;
            border: 1px solid $primary-color;
            border-radius: 4rem;

            &:hover{
                background-color: $white;
                border-color: $white;
                color: $primary-color;
            }
        }

        &>ul {
            list-style: none;
            margin: 0;
            padding: 0;

            &>li {
                margin: 0;                
                position: relative;
                display: flex;
                align-items: center;

                &.menu-item-has-children {

                    &:hover {
                        &>a {
                            color: $primary-color;
                        }

                        ul.sub-menu {
                            display: block;
                        }
                    }
                }

                &.current_page_item{
                    & > a{
                        color: $primary-color;
                    }
                }
            }
        }
    }

    .logo-bk {
        padding-right: 1rem;

        @include mq(lg){
            padding: 0rem 2rem;
        }
        @include mq(xl){
            padding: 0rem 2.5rem;
        }

        @include mq(mw6) {
            padding-right: 1rem;
        }

        a {
            display: block;
        }

        img {
            width: auto;
            height: 5rem;

            @include mq(sm) {
                height: 6.5rem;
            }
        }
    }


    &.fixed-top {
        position: fixed;
        width: 100%;        
        -webkit-animation: 0.7s fade-in;
        animation: 0.7s fade-in;
        z-index: 999;
        animation: 0.7s ease-in-out 0.1s normal both 1 running stickyhead;
        box-shadow: $box-shadow-hover;                                    
    }

}

@keyframes slide {
    0% {
        transform: translateX(10px);
        opacity: 0.2;
    }

    100% {
        transform: translateX(0px);
        opacity: 1;
    }
}


@keyframes stickyhead {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

.bk-header {
    li.menu-item-has-children {
        ul.sub-menu {
            display: none;
            white-space: nowrap;
            min-width: 14rem;
            margin: 0;
            padding: 0;
            list-style: none;
            text-align: left;
            font-weight: 400;
            background-color: $white;
            box-shadow: 0 0.15rem 0.25rem rgba(0, 0, 0, 0.1);
            position: absolute;
            top: 100%;
            left: 0;
            margin: 0;
            animation: slide .2s ease;
            padding: 0.25rem 0rem;

            li {
                width: 100%;
                margin: 0;
                padding: 0.25rem 0;
                position: relative;
                transition: 0.4s;

                a {
                    color: $primary-color;
                }

                &:hover {
                    background-color: $primary-color;

                    &>ul {
                        display: block;
                    }

                    &>a {
                        color: $white;
                    }
                }

                a {
                    display: block;
                    padding: 0.5rem 1rem;                    

                    &::after {
                        display: none;
                    }
                }

                ul {
                    display: none;
                    min-width: 12rem;
                    margin: 0;
                    padding: 0;
                    list-style: none;
                    position: absolute;
                    top: 0;
                    left: 100%;
                    transform: translateX(-1px);
                    background-color: $white;
                    box-shadow: 0 0.15rem 0.25rem rgba(0, 0, 0, 0.1);
                }
            }
        }
    }
}