@keyframes flare {
    0% {
        width: 0;
        left: -10%;
    }

    50% {
        width: 50%;
    }

    100% {
        width: 0;
        left: 110%;
    }
}

.btn {
    @extend %Button;
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    min-width: 8rem;
    padding: 1.2rem 1.4rem;
    text-transform: uppercase;
    border-radius: 3rem;

    path {
        transition: .4s all ease;
    }

    &:hover {
        path {
            fill: $primary-color;
        }
    }
}

.dark-btn {
    background-color: $secondary-color;

    &:hover {
        color: $secondary-color;
        border-color: $secondary-color;
    }
}

.btn-bold {
    font-weight: 700;
    padding: 1rem 1.8rem;
}

.btn-border {
    background-color: transparent;
    color: $primary-color;
    border-color: $primary-color;

    &:active,
    &:focus {
        background-color: transparent;
        color: $primary-color;
        border-color: $primary-color;
    }

    &:hover {
        background-color: transparent;
        color: $primary-color;
        border-color: $primary-color;
    }
}

.btn-primary {
    background-color: $primary-color;
    border-color: $primary-color;
    color: $primary-font-color;
}

.btn-secondary {
    border-radius: 0;
    font-size: 1rem;
    font-weight: 700;
    padding: 0.7rem 1rem;
}


.direcion-btn {
    background-color: transparent;
    color: $secondary-font-color;
    border: 1px solid $black;
    border-radius: 3px;
    font-weight: 600;
    padding: 0.45rem 0.6rem;
    text-transform: uppercase;
    transition: all .2s ease-in;

    svg {
        width: 26px;
        height: 20px;
    }

    &:hover {
        color: $primary-color;
        border-color: $primary-color;
    }
}