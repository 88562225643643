.bk-wrapper{
  width: 100%;
  overflow: hidden;

	&.active{
		.bk-ui{
      right: 280px;
      @include mq(xl){
        right: 0;
      }
    }
	}

  .bk-ui{
    position: relative;
    right: 0;
  	transition: .4s;
  }
}
