.h1 {
  font-size: 3rem;
  font-weight: 600;
  line-height: 1;
  text-transform: capitalize;
  letter-spacing: 2px;

  @include mq(sm) {
    font-size: 4rem;
  }

  @include mq(lg) {
    font-size: 5rem;
  }

  @include mq(xl) {
    font-size: 6rem;
  }
}

h1 {
  @extend .h1;
}

.h1,
.h2,
.h3,
.h4 {
  margin-bottom: 1rem;
  font-family: $secondary-family;
}

.h2 {
  font-size: 3rem;  
  line-height: 1.2;  
  font-weight: 700;

  @include mq(md) {
    font-size: 3.7rem;
  }
}

h2 {
  @extend .h2;
}

.h3 {
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.1;  
  
  @include mq(sm){
    font-size: 2.2rem;
  }
}

h3 {
  @extend .h3;
}