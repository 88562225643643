
@for $i from 0 through 10 {
  .size-#{$i}{
    font-size: $font-size + (.1*$i);
  }
}

@include mq('md') {
  @for $i from 0 through 10 {
    .size-md-#{$i}{
      font-size: $font-size + (.1*$i);
    }
  }
}

@include mq('lg') {
  @for $i from 0 through 10 {
    .size-lg-#{$i}{
      font-size: $font-size + (.1*$i);
    }
  }
}

@include mq('xl') {
  @for $i from 0 through 10 {
    .size-xl-#{$i}{
      font-size: $font-size + (.1*$i);
    }
  }
}

@include mq('xxl') {
  @for $i from 0 through 10 {
    .size-xxl-#{$i}{
      font-size: $font-size + (.1*$i);
    }
  }
}
