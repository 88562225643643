%Container{
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @include mq(sm){
    max-width: 540px;
  }
  @include mq(md){
    max-width: 720px;
  }
  @include mq(lg){
    max-width: 960px;
  }
  @include mq(xl){
    max-width: 1140px;
  }
}
%ObjectCover {
  position: absolute;
  top: 0;
  left: 0;  
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;    
}

%ObjectContain {
  position: absolute;
  top: 0;
  left: 0;  
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;    
}
%ResetButton {
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: none;
}
%SrOnly {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0,0,0,0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0;
}

%Fa {
  display: inline-block;
  font: normal normal normal 100%/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

%Auto {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
}

%Shadow {
  box-shadow: 0 5px 45px rgba(0,0,0,0.2);
}
%Button{
  @extend %ResetButton;
  display: inline-block;
  position: relative;
  z-index: 0;
  text-align: center;  
  background-color: $primary-color;
  color: $white;
  border-color: $primary-color;
  border-radius: 0;
  border: solid 1px transparent;
  font-family: $primary-family;
  font-size: $button-font-size;
  font-weight: $button-font-weight;
  line-height: 1.1rem;
  transition: .4s all ease;
  letter-spacing: 2px;

  &:focus,
  &:active{
    box-shadow: none;
    background-color: $white;
    color: $primary-color;
    border-color: $primary-color;
  }
  &:hover{
    box-shadow: none;
    background-color: $white;
    color: $primary-color;
    border-color: $primary-color;
  }
}
%Input{
  @extend %ResetButton;
  height: 2.2rem;
  font-size: .9rem;
  padding-left: 1rem;
  padding-right: 1rem;
  appearance: none;
  border-radius: $border-radius;
  border: solid 1px $black;
  color: $text;
  @include mq(md){
    height: 2.4rem;
  }
  @include mq(xl){
    height: 2.6rem;
  }

  &::placeholder{
    color: $light-text;
  }

  &:focus,
  &:active{
    border-color: $primary-color;
  }
  &.invalid{
    border-color: $danger;
  }
}
