// Spacing Margin
@for $i from 0 through 10 {
  .mgt-#{$i} {
    margin-top: #{$i}rem;
  }
  .mgb-#{$i} {
    margin-bottom: #{$i}rem;
  }
  .mgl-#{$i} {
    margin-left: #{$i}rem;
  }
  .mgr-#{$i} {
    margin-right: #{$i}rem;
  }

  .mgt--#{$i} {
    margin-top: -#{$i}rem;
  }
  .mgb--#{$i} {
    margin-bottom: -#{$i}rem;
  }
  .mgl--#{$i} {
    margin-left: -#{$i}rem;
  }
  .mgr--#{$i} {
    margin-right: -#{$i}rem;
  }
}

@include mq('sm') {
  @for $i from 0 through 10 {
    .mgt-sm-#{$i} {
      margin-top: #{$i}rem;
    }
    .mgb-sm-#{$i} {
      margin-bottom: #{$i}rem;
    }
    .mgl-sm-#{$i} {
      margin-left: #{$i}rem;
    }
    .mgr-sm-#{$i} {
      margin-right: #{$i}rem;
    }

    .mgt-sm--#{$i} {
      margin-top: -#{$i}rem;
    }
    .mgb-sm--#{$i} {
      margin-bottom: -#{$i}rem;
    }
    .mgl-sm--#{$i} {
      margin-left: -#{$i}rem;
    }
    .mgr-sm--#{$i} {
      margin-right: -#{$i}rem;
    }
  }
}

@include mq('md') {
  @for $i from 0 through 10 {
    .mgt-md-#{$i} {
      margin-top: #{$i}rem;
    }
    .mgb-md-#{$i} {
      margin-bottom: #{$i}rem;
    }
    .mgl-md-#{$i} {
      margin-left: #{$i}rem;
    }
    .mgr-md-#{$i} {
      margin-right: #{$i}rem;
    }

    .mgt-md--#{$i} {
      margin-top: -#{$i}rem;
    }
    .mgb-md--#{$i} {
      margin-bottom: -#{$i}rem;
    }
    .mgl-md--#{$i} {
      margin-left: -#{$i}rem;
    }
    .mgr-md--#{$i} {
      margin-right: -#{$i}rem;
    }
  }
}

@include mq('lg') {
  @for $i from 0 through 10 {
    .mgt-lg-#{$i} {
      margin-top: #{$i}rem;
    }
    .mgb-lg-#{$i} {
      margin-bottom: #{$i}rem;
    }
    .mgl-lg-#{$i} {
      margin-left: #{$i}rem;
    }
    .mgr-lg-#{$i} {
      margin-right: #{$i}rem;
    }

    .mgt-lg--#{$i} {
      margin-top: -#{$i}rem;
    }
    .mgb-lg--#{$i} {
      margin-bottom: -#{$i}rem;
    }
    .mgl-lg--#{$i} {
      margin-left: -#{$i}rem;
    }
    .mgr-lg--#{$i} {
      margin-right: -#{$i}rem;
    }
  }
}

@include mq('xl') {
  @for $i from 0 through 10 {
    .mgt-xl-#{$i} {
      margin-top: #{$i}rem;
    }
    .mgb-xl-#{$i} {
      margin-bottom: #{$i}rem;
    }
    .mgl-xl-#{$i} {
      margin-left: #{$i}rem;
    }
    .mgr-xl-#{$i} {
      margin-right: #{$i}rem;
    }

    .mgt-xl--#{$i} {
      margin-top: -#{$i}rem;
    }
    .mgb-xl--#{$i} {
      margin-bottom: -#{$i}rem;
    }
    .mgl-xl--#{$i} {
      margin-left: -#{$i}rem;
    }
    .mgr-xl--#{$i} {
      margin-right: -#{$i}rem;
    }
  }
}

@include mq('xxl') {
  @for $i from 0 through 10 {
    .mgt-xxl-#{$i} {
      margin-top: #{$i}rem;
    }
    .mgb-xxl-#{$i} {
      margin-bottom: #{$i}rem;
    }
    .mgl-xxl-#{$i} {
      margin-left: #{$i}rem;
    }
    .mgr-xxl-#{$i} {
      margin-right: #{$i}rem;
    }

    .mgt-xxl--#{$i} {
      margin-top: -#{$i}rem;
    }
    .mgb-xxl--#{$i} {
      margin-bottom: -#{$i}rem;
    }
    .mgl-xxl--#{$i} {
      margin-left: -#{$i}rem;
    }
    .mgr-xxl--#{$i} {
      margin-right: -#{$i}rem;
    }
  }
}

// Spacing Padding

@for $i from 0 through 10 {
  .pdt-#{$i} {
    padding-top: #{$i}rem;
  }
  .pdb-#{$i} {
    padding-bottom: #{$i}rem;
  }
  .pdl-#{$i} {
    padding-left: #{$i}rem;
  }
  .pdr-#{$i} {
    padding-right: #{$i}rem;
  }

  .pdt--#{$i} {
    padding-top: -#{$i}rem;
  }
  .pdb--#{$i} {
    padding-bottom: -#{$i}rem;
  }
  .pdl--#{$i} {
    padding-left: -#{$i}rem;
  }
  .pdr--#{$i} {
    padding-right: -#{$i}rem;
  }

  .pd-#{$i} {
    padding: #{$i}rem;
  }
}

@include mq('md') {
  @for $i from 0 through 10 {
    .pdt-md-#{$i} {
      padding-top: #{$i}rem;
    }
    .pdb-md-#{$i} {
      padding-bottom: #{$i}rem;
    }
    .pdl-md-#{$i} {
      padding-left: #{$i}rem;
    }
    .pdr-md-#{$i} {
      padding-right: #{$i}rem;
    }

    .pdt-md--#{$i} {
      padding-top: -#{$i}rem;
    }
    .pdb-md--#{$i} {
      padding-bottom: -#{$i}rem;
    }
    .pdl-md--#{$i} {
      padding-left: -#{$i}rem;
    }
    .pdr-md--#{$i} {
      padding-right: -#{$i}rem;
    }

    .pd-md-#{$i} {
      padding: #{$i}rem;
    }
  }
}

@include mq('lg') {
  @for $i from 0 through 10 {
    .pdt-lg-#{$i} {
      padding-top: #{$i}rem;
    }
    .pdb-lg-#{$i} {
      padding-bottom: #{$i}rem;
    }
    .pdl-lg-#{$i} {
      padding-left: #{$i}rem;
    }
    .pdr-lg-#{$i} {
      padding-right: #{$i}rem;
    }

    .pdt-lg--#{$i} {
      padding-top: -#{$i}rem;
    }
    .pdb-lg--#{$i} {
      padding-bottom: -#{$i}rem;
    }
    .pdl-lg--#{$i} {
      padding-left: -#{$i}rem;
    }
    .pdr-lg--#{$i} {
      padding-right: -#{$i}rem;
    }
    
    .pd-lg-#{$i} {
      padding: #{$i}rem;
    }
  }
}

@include mq('xl') {
  @for $i from 0 through 10 {
    .pdt-xl-#{$i} {
      padding-top: #{$i}rem;
    }
    .pdb-xl-#{$i} {
      padding-bottom: #{$i}rem;
    }
    .pdl-xl-#{$i} {
      padding-left: #{$i}rem;
    }
    .pdr-xl-#{$i} {
      padding-right: #{$i}rem;
    }

    .pdt-xl--#{$i} {
      padding-top: -#{$i}rem;
    }
    .pdb-xl--#{$i} {
      padding-bottom: -#{$i}rem;
    }
    .pdl-xl--#{$i} {
      padding-left: -#{$i}rem;
    }
    .pdr-xl--#{$i} {
      padding-right: -#{$i}rem;
    }

    .pd-xl-#{$i} {
      padding: #{$i}rem;
    }
  }
}

@include mq('xxl') {
  @for $i from 0 through 10 {
    .pdt-xxl-#{$i} {
      padding-top: #{$i}rem;
    }
    .pdb-xxl-#{$i} {
      padding-bottom: #{$i}rem;
    }
    .pdl-xxl-#{$i} {
      padding-left: #{$i}rem;
    }
    .pdr-xxl-#{$i} {
      padding-right: #{$i}rem;
    }

    .pdt-xxl--#{$i} {
      padding-top: -#{$i}rem;
    }
    .pdb-xxl--#{$i} {
      padding-bottom: -#{$i}rem;
    }
    .pdl-xxl--#{$i} {
      padding-left: -#{$i}rem;
    }
    .pdr-xxl--#{$i} {
      padding-right: -#{$i}rem;
    }

    .pd-xxl-#{$i} {
      padding: #{$i}rem;
    }
  }
}
