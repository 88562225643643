.our-locations {
    background: url('../images/location-bg.jpg') no-repeat center / cover;
    background-attachment: fixed;
}

.catering {
    background: url('../images/location-bg.jpg') repeat center / 100%;
}

.catering-card {
    background-color: $white;
    box-shadow: 1px 0 10px rgba(0, 0, 0, 0.5);
    padding: 3rem 2rem;

    header {
        text-align: center;
        position: relative;
        padding-bottom: 0.5rem;

        &::after,
        &::before {
            content: "";
            position: absolute;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: $secondary-color;
        }

        &::after {
            bottom: 0;
        }

        &::before {
            bottom: 3px;
        }

        .h3 {
            font-family: $tertiary-family;
            margin-bottom: 0;
            font-size: 3.5rem;

            @include mq(xl) {
                font-size: 4rem;
            }
        }
    }
}

.bk-catering-order {
    background-color: $white;
    max-width: 56rem;
    margin: auto;

    .title {
        font-family: $primary-family;
        font-weight: 600;
    }
}

.connect-icons {
    svg {
        width: 24px;
        height: 24px;
    }

    a {
        margin: 0 3px;
    }
}

.cat-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3rem;
    border: none;

    .nav-link {
        font-size: 1rem;
        color: $secondary-font-color !important;
        margin: 0 1rem;
        position: relative;
        padding: 0.5rem 0rem;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 2px;
        position: relative;
        background: transparent;
        border: none;
        outline: 0;

        @include mq(sm) {
            font-size: 1.3rem;
        }

        &::before,
        &::after {
            content: "";
            position: absolute;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: $secondary-color;
            opacity: 0;
            transition: all 0.2s ease;
        }

        &::before {
            top: 0;
        }

        &::after {
            bottom: 0;
        }

        &.active {

            &::before,
            &::after {
                opacity: 1;
            }
        }

        &:hover {

            &::before,
            &::after {
                opacity: 1;
            }
        }
    }
}

// .menu-grid {
//     column-count: 1;
//     column-gap: 4rem;
//     margin-bottom: -4rem;

//     @include mq(lg) {
//         column-count: 2;
//     }
// }

.menu-grid {
    margin-left: -2rem;
    margin-right: -2rem;

    .bk-food-menu {        
        margin-bottom: 4rem;
        float: left;
        padding-left: 2rem;
        padding-right: 2rem;
        width: 100%;
    
        @include mq(lg) {
            width: 50%;
        }
    }
}