.bk-banner {
    position: relative;
    color: $primary-font-color;

    .content-bk {
        position: absolute;
        left: 0;
        width: 100%;
        top: 56%;
        transform: translateY(-50%);
        z-index: 9;
        text-align: center;

        @include mq(mmd) {
            padding: 0 4rem;
        }

        @include mq(msm) {
            padding: 0 3rem;
        }

        .btn {
            margin-top: 4rem;
            font-size: 1.3rem;
            padding: 1.2rem 1.4rem;
            box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

            @include mq(xl) {
                font-size: 1.4rem;
                padding: 1.4rem 1.4rem;
            }
        }
    }

    .sub-heading {
        font-family: $tertiary-family;
        font-size: 2rem;
        line-height: 1;
        text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);

        @include mq(sm) {
            font-size: 3rem;
        }

        @include mq(xl) {
            font-size: 4rem;
        }
    }

    .h1 {
        margin-bottom: 0;
        text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);

        @include mq(msm) {
            margin-bottom: 1rem;
        }
    }

    .owl-buttons {

        .left-btn,
        .right-btn {
            position: absolute;
            top: 56%;
            transform: translateY(-50%);
            z-index: 10;
            background-color: transparent;
            border: none;
            outline: 0;
            color: $white;
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 50%;
            background-color: rgba(255, 255, 255, 0.3);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.8rem;

            @include mq(sm) {
                width: 3rem;
                height: 3rem;
            }
        }

        .left-btn {
            left: 1rem;

            @include mq(md) {
                left: 2rem;
            }
        }

        .right-btn {
            right: 1rem;

            @include mq(md) {
                right: 2rem;
            }
        }
    }

    .scroll-btn {
        background-color: transparent;
        border: none;
        outline: 0;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 2rem;
        color: $primary-font-color;
        z-index: 99;
        font-size: 2.2rem;

        &:hover {
            color: $primary-color;
        }

        svg {
            width: 40px;
            height: 40px;
        }
    }
}

.banner-slider {
    position: relative;

    .item {
        height: 32rem;
        position: relative;
        z-index: 7;

        @include mq(sm) {
            height: 40rem;
        }

        @include mq(lg) {
            height: 50rem;
        }

        @include mq(w3) {
            height: 58rem;
        }

        figure {
            margin-bottom: 0;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
    }

    &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 8;
    }
}

.inner-banner {
    
    .bg, .banner-slider .item {
        height: 24rem;
        position: relative;

        @include mq(sm) {
            height: 30rem;
        }

        @include mq(xl) {
            height: 35rem;
        }



        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
        }

        &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.2);
            z-index: 2;
        }
    }

    .sub-heading {
        margin-bottom: 1rem;
    }

    .content-bk {
        top: 60%;
    }
}