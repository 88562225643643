.modal-dialog{
  margin: 2.75rem auto;

  &.dialog-form{
    max-width: 650px;
  }

  &.dialog-content{
    max-width: 750px;
  }

  .close{
    width: 1.8rem;
    height: 1.8rem;
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 1;
    border: solid 1px $text;
    border-radius: 50%;
    color: $text;
    opacity: 1;
    text-indent: -9999999px;

    &::before,&::after{
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      display: block;
      content: '';
      background-color: $text;
      transform: rotate(45deg);
      transition: all .4s;
    }

    &::before{
      width: 50%;
      height: 2px;
    }
    &::after{
      width: 2px;
      height: 50%;
    }

    &:hover{
      opacity: 1;

      &::before,&::after{
        opacity: 0.8;
      }
    }
  }
  .modal-content{
    border-radius: $border-radius;
  }
  .modal-body{
    padding: 2.5rem 2rem 2rem;
  }
  header{
    p{
      margin-top: -1rem;
    }
  }
}
