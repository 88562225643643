html{
  font-size: .9rem;
}
body{
  margin: 0;
  font-family: $primary-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $secondary-font-color;
  font-size: 1rem;
  font-weight: 400;
  line-height: $line-height;  

  @include mq(sm){
    font-size: 1.1rem;
  }
}
@include anchor() {
  text-decoration: none;
  outline-width: 0;
}
@mixin input() {
  outline: none;
  outline-width: 0;
  box-shadow: none;
  border: none;
}
a{
  color: $secondary-color;

  &:hover{
    color: $primary-color;
  }

  path{
      transition: all 0.2s ease;
  }
}

img{
  max-width: 100%;
  height: auto;
}
b{
  font-weight: 600;
}
strong{
  font-weight: 700;
}
hr{
  border-color: $black;
}
sup{
  font-size: 80%;
}
fieldset{
  label{
    font-weight: 600;
    margin-bottom: .25rem;
  }
}
