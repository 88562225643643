@font-face {
    font-family: 'icomoon';
    src:  url('../fonts/icomoon.eot?72bi1y');
    src:  url('../fonts/icomoon.eot?72bi1y#iefix') format('embedded-opentype'),
      url('../fonts/icomoon.ttf?72bi1y') format('truetype'),
      url('../fonts/icomoon.woff?72bi1y') format('woff'),
      url('../fonts/icomoon.svg?72bi1y#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-tiktok:before {
    content: "\e900";
  }
  .icon-instagram:before {
    content: "\e901";
  }
  .icon-brand:before {
    content: "\e901";
  }
  .icon-social:before {
    content: "\e901";
  }
  .icon-compass:before {
    content: "\e902";
  }
  .icon-direction:before {
    content: "\e902";
  }
  .icon-location:before {
    content: "\e902";
  }
  .icon-twitter:before {
    content: "\e903";
  }
  .icon-facebook:before {
    content: "\e904";
  }
  .icon-chevron-down:before {
    content: "\e905";
  }
  .icon-arrow-right:before {
    content: "\e906";
  }
  .icon-arrow-left:before {
    content: "\e907";
  }
  