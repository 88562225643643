
@mixin anchor() {
  a {
    @content;

    &:hover {
      @content;
    }

    &:active {
      @content;
    }

    &:visited {
      @content;
    }

    &:focus {
      @content;
    }
  }
}
@mixin input() {
  button,
  input,
  input.form-control,
  select,
  select.form-control,
  textarea,
  textarea.form-control {
    @content;

    &:hover {
      @content;
    }

    &:active {
      @content;
    }

    &:visited {
      @content;
    }

    &:focus {
      @content;
    }
  }
}
