.bk-branch {
    padding: 2.5rem 1.5rem;
    border: 7px solid $primary-color;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.8);
    height: 100%;

    address {
        font-weight: 700;
        margin-bottom: 2rem;

        a {
            &:hover {
                color: $primary-color;
            }
        }
    }

    .direcion-btn {
        margin-bottom: 1rem;

        i{
            font-size: 1.2rem;
        }
    }

    .distance {        
        margin-bottom: 1rem;
    }

    .phone {
        display: block;
        font-weight: 700;
        margin-bottom: 0.5rem;

        &:hover {
            color: $primary-color;
        }
    }

    .button {
        display: block;
        background-color: $primary-color;
        color: $primary-font-color;
        padding: 0.5rem 1rem;
        max-width: 20rem;
        margin: auto;
        margin-bottom: 0.4rem;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 1rem;
        transition: all .2s ease;

        &:hover {
            background-color: $secondary-color;
        }

        &.black-button {
            background-color: $secondary-color;

            &:hover {
                background-color: $primary-color;
            }
        }
    }   

    .social-icons {
        a{
            margin: 0 0.2rem;

            &:hover{
                color: $primary-color;
            }
        }

        i{
            font-size: 1.7rem;
        }

        svg {
            width: 24px;
            height: 24px;
        }
    }
}