.form-group {
    
    label {
        font-weight: 500;
        display: block;
        margin-bottom: 0.4rem;
    }

    .form-required{
        color: $primary-color;
    }

    input, select {
        width: 100%;
        height: 40px;
        border-radius: 3px;
        outline: 0;
        border: 1px solid $border-color;
        padding: 0 1rem;

        &:focus{
            border-color: $primary-color;
        }
    }

    .form-sub-label{
        display: block;
        color: #57647e;
        font-size: 0.9rem;
        margin-top: 0.4rem;
    }

    textarea{
        width: 100%;
        height: 14rem;
        border-radius: 3px;
        outline: 0;
        border: 1px solid $border-color;
        padding: 1rem;
        resize: none;

        &:focus{
            border-color: $primary-color;
        }
    }
}