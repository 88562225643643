// Spacing Margin
@for $i from 0 through 20{
  .mxw-#{$i} {
    max-width: #{$i * 50}px;
  }
}

@include mq('sm') {
  @for $i from 0 through 20{
    .mxw-sm-#{$i} {
      max-width: #{$i * 50}px;
    }
  }
}

@include mq('md') {
  @for $i from 0 through 20{
    .mxw-md-#{$i} {
      max-width: #{$i * 50}px;
    }
  }
}

@include mq('lg') {
  @for $i from 0 through 20{
    .mxw-lg-#{$i} {
      max-width: #{$i * 50}px;
    }
  }
}

@include mq('xl') {
  @for $i from 0 through 20{
    .mxw-xl-#{$i} {
      max-width: #{$i * 50}px;
    }
  }
}

@include mq('xxl') {
  @for $i from 0 through 20{
    .mxw-xl-#{$i} {
      max-width: #{$i * 50}px;
    }
  }
}
