.bk-pagination{
  margin-top: 2rem;
  a,
  span {
    width: 2.4rem;
    height: 2.4rem;
    line-height: 2.4rem;
    border: solid 1px $primary-color;
    border-radius: 50%;
    margin: 0 2px;
    padding: 0 .5rem;
    font-size: .9rem;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    color: $primary-color;
    transition: .4s;

    &:hover{
      border-color: $primary-color;
      background-color: $primary-color;
      color: $white;
    }
  }
  a{
    cursor: pointer;
  }
  .current{
    border-color: $primary-color;
    background-color: $primary-color;
    color: $white;
  }
}
