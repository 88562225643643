.bk-mob-tog {
  position: relative;
  height: 3rem;
  margin-left: .8rem;
  padding: 0 0 0 2rem;
  outline: 0 !important;
  color: $white;
  background-color: transparent;
  box-shadow: none !important;
  border: none;

  @include mq(sm) {
    margin-left: 1rem;
    padding-left: 2rem;
  }

  &.active {
    .bk-burger-menu {
      &::before {
        transform: translate(-50%, -50%) translateY(0) rotate(-45deg);
      }

      &::after {
        transform: translate(-50%, -50%) translateY(0) rotate(45deg);
      }
    }
  }

  .bk-burger-menu {
    width: 2.2rem;
    height: .4rem;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);

    &::before,
    &::after {
      width: .8rem;
      height: 1px;
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      background-color: $white;
      transition: transform .4s;

      @include mq(sm) {
        width: 1.2rem;
        height: 2px;
      }
    }

    &::before {
      transform: translate(-50%, -50%) translateY(-2px) rotate(0);

      @include mq(sm) {
        transform: translate(-50%, -50%) translateY(-3px) rotate(0);
      }
    }

    &::after {
      transform: translate(-50%, -50%) translateY(2px) rotate(0);

      @include mq(sm) {
        transform: translate(-50%, -50%) translateY(3px) rotate(0);
      }
    }
  }

  span {
    display: block;
    text-transform: uppercase;
    font-size: .8rem;
    font-weight: 600;

    @include mq(sm) {
      font-size: .9rem;
      margin-left: 1.5rem;
    }
  }
}

.bk-mob-nav-mask {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 320;
  transform: translateX(-100%);
  width: 100%;
  height: 100%;
  transition: .4s;

  &.active {
    transform: translateX(0);
  }
}

.bk-mob-nav {
  background-color: $secondary-color;
  color: $primary-font-color;
  width: 280px;
  height: 100%;
  position: fixed;
  top: 0;
  right: -280px;
  z-index: 321;
  transition: 0.4s;
  z-index: 999999;

  &.active {
    right: 0;
  }

  .contain-bk {
    width: 100%;
    height: 100%;
    padding: 1.2rem 0;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }

  a {
    display: inline-block;
    color: $white;

    &::after {
      background-color: $white;
    }

    &:hover {
      color: $white;
    }

    &.order-btn{
      background-color: $primary-color;
      color: $primary-font-color;
      border-radius: 5px;
    }
  }

  .list-bk {
    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        position: relative;
        margin: 0;
        padding: .5rem 0;

        &:first-child {
          border-top: none;
        }

        a {
          position: relative;
          margin-left: 1.5rem;
          margin-right: 3rem;
          padding: 0.6rem 2rem;
          font-size: 1rem;
          font-weight: 500;
          text-transform: uppercase;
        }

        button {
          position: absolute;
          right: 1rem;
          top: 15px;
          background-color: transparent;
          border: none;
          outline: 0;

          &:after {
            content: url("../images/right-arrow-angle.svg");
            position: absolute;
            left: 0;
            top: 0;
            transition: all .2s ease;
          }
        }

        ul {
          display: none;
          background-color: rgba(255, 255, 255, 0.1);
          color: $primary-font-color;

          li {
            border-top: none;

            button {
              background-color: $primary-color;
              color: $primary-font-color;
            }

            ul {
              margin-left: 1rem;
              background-color: $primary-color;
              color: $primary-font-color;

              ul {
                background-color: rgba(255, 255, 255, 0.1);
              }
            }

            a {
              position: relative;
              text-transform: none;
            }            
          }
        } 
        &.current_page_item{
          a{
            color: $primary-color;
          }
        }       
      }
    }

    .active {
      >button {
        &:after {
          transform: rotate(90deg);
        }
      }
    }

  }
}