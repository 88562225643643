.border-radius {
    border-radius: $border-radius;
}

.radius {
    border-radius: $border-radius;
}

@for $i from 0 through 10 {
    .radius-#{$i} {
        border-radius: $border-radius+(.3*$i);
    }
}

.bk-obj-cover {
    width: 100%;
    padding-bottom: 100%;
    margin-bottom: 0;
    position: relative;

    img {
        @extend %ObjectCover;
    }
}

.no-gutters {
    .bk-obj-md-cover {
        @include mq(md) {
            left: 0;
            right: 0;
        }
    }
}

.bk-obj-md-cover {
    width: 100%;
    margin-bottom: 0;
    padding-bottom: 80%;
    position: relative;
    z-index: 0;

    @include mq(md) {
        width: auto;
        height: 100%;
        padding-bottom: 0;
        position: absolute;
        top: 0;
        left: 15px;
        right: 15px;
    }

    img {
        @extend %ObjectCover;
    }
}

.bk-obj-contain {
    width: 100%;
    padding-bottom: 100%;
    margin-bottom: 0;
    position: relative;

    img {
        @extend %ObjectContain;
    }
}

.bk-obj-md-cover {
    width: 100%;
    margin-bottom: 0;
    padding-bottom: 80%;
    position: relative;
    z-index: 0;

    @include mq(md) {
        width: auto;
        height: 100%;
        padding-bottom: 0;
        position: absolute;
        top: 0;
        left: 15px;
        right: 15px;
    }

    img {
        @extend %ObjectContain;
    }
}

blockquote {
    font-size: 1.2rem;
    font-style: italic;
    margin-bottom: 0;

    p {
        display: inline-block;
        margin-bottom: 0;
    }

    cite {
        font-family: $primary-family;
        font-size: 1rem;
        font-weight: 400;
        font-style: normal;
    }
}

.relative {
    position: relative;
}

.img-w-100 {
    img {
        width: 100%;
    }
}

.cmn-border {
    border: 1px solid $border-color;
}

.cmn-border-bottom {
    border-bottom: 1px solid $border-color;
}

.cmn-border-top {
    border-top: 1px solid $border-color;
}

.fw-medium {
    font-weight: 500;
}

.fw-semibold {
    font-weight: 600;
}

.f-primary {
    font-family: $primary-family;
}

.f-secondary {
    font-family: $secondary-family;
}