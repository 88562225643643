.container-fluid{
    @include mq(md){
        padding: 0 5%;
    }
}
.container,
.container-md{
  @include mq(w6){
    max-width: 1300px;
  }
}

.badge{
	min-width: 54px;
}
