.bk-food-menu {
    display: inline-block;
    width: 100%;
    margin-bottom: 4rem;

    a {
        color: $secondary-font-color;

        &:hover {
            .thumb-bk {
                figure {
                    border-color: $primary-color;
                }
            }
        }
    }

    .title {
        color: $primary-color;
        flex: 0 0 calc(100% - 6rem);
        max-width: calc(100% - 6rem);
        margin-bottom: 0;
    }

    .price {
        font-size: 1.3rem;
        font-weight: 300;
        text-align: right;
        flex: 0 0 6rem;
        max-width: 6rem;

        @include mq(xl) {
            font-size: 1.5rem;
        }
    }

    .thumb-bk {
        position: relative;

        figure {
            border-radius: $border-radius;
            border-bottom: 3px solid transparent;
            overflow: hidden;
            transition: all 0.2s ease;
        }
    }

    .review-bk {
        background-color: rgba(33, 33, 33, .85);
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -10px;
        color: $primary-font-color;
        border-radius: 20px;
        padding: 5px 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.9rem;
        width: max-content;
    }

    .bubbles {
        display: flex;
        align-items: center;
        padding-right: 0.8rem;
        margin-right: 0.8rem;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 1px;
            height: 100%;
            background-color: $white;
        }
    }

    .bubble {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $secondary-color;
        position: relative;

        &.bubble {
            background-color: #e03d43;
        }

        &.thumb {
            background-color: #fea623;
            left: -7px;
        }

        svg {
            width: 11px;
        }
    }
}