// Spacing Margin
@for $i from 0 through 10{
  .obj-h-#{$i * 10} {    
    padding-bottom: #{$i * 10%};
  }
}

@include mq(sm) {
  @for $i from 0 through 10{
    .obj-sm-h-#{$i * 10} {
      padding-bottom: #{$i * 10%};
    }
  }
}

@include mq(md) {
  @for $i from 0 through 10{
    .obj-md-h-#{$i * 10} {
      padding-bottom: #{$i * 10%};
    }
  }
}

@include mq(lg) {
  @for $i from 0 through 10{
    .obj-lg-h-#{$i * 10} {
      padding-bottom: #{$i * 10%};
    }
  }
}

@include mq(xl) {
  @for $i from 0 through 10{
    .obj-xl-h-#{$i * 10} {
      padding-bottom: #{$i * 10%};
    }
  }
}

@include mq(xxl) {
  @for $i from 0 through 10{
    .obj-xxl-h-#{$i * 10} {
      padding-bottom: #{$i * 10%};
    }
  }
}
