.bk-footer {
  background-color: $secondary-color;
  color: $primary-font-color;

  .top-bk {
    background-color: #101010;
    padding: 2rem 0rem;
  }

  .social-icons {
    a {
      color: $white;
      margin: 0 1rem;
      transition: all 0.2s ease;
      font-size: 2rem;

      &:hover {
        color: $primary-color;
      }
    }

    svg {
      width: 2rem;
      height: 2rem;
    }
  }

  .title {
    font-size: 1.2rem;
    font-weight: 700;
    font-family: $primary-family;
    margin-bottom: 1.5rem;
  }

  .location-name {
    font-style: italic;
    margin-bottom: 0.5rem;
  }

  address {
    margin-bottom: 0.5rem;
  }

  .connect {
    color: $primary-font-color;
    display: block;

    &:hover {
      color: $primary-color;
    }
  }

  .bottom-bk {
    .social-icons {
      margin-top: 1rem;

      a {
        margin: 0 0.3rem;
        font-size: 1.6rem;

        &:first-child {
          margin-left: 0;
        }
      }

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  .link {
    ul {
      padding-left: 0;
      margin-bottom: 0;
    }

    li {
      list-style: none;
      margin-bottom: 1rem;
    }

    a {
      color: $primary-font-color;

      &:hover {
        color: $primary-color;
      }
    }
  }

  .logo {
    img {
      width: 7rem;
    }
  }
}