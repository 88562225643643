.d-flex-parent>* {
  display: flex;
}

.flex-wrap-parent>* {
  flex-wrap: wrap;
}

.bk-page-nav {
  background-color: $white;
  text-align: center;

  a {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
}

::selection {
  color: $white;
  background-color: $primary-color;
}

@mixin cmmn_spacing() {
  padding: 3rem 0rem;

  @include mq(md) {
    padding: 4rem 0rem;
  }

  @include mq(xl) {
    padding: 6em 0rem;
  }

}

.spacing {
  @include cmmn_spacing;
}

@mixin cmmn_spacing_top() {
  padding-top: 3rem;

  @include mq(md) {
    padding-top: 4rem;
  }

  @include mq(xl) {
    padding-top: 6em;
  }
}

.spacing-top {
  @include cmmn_spacing_top;
}

@mixin cmmn_spacing_bottom() {
  padding-bottom: 3rem;

  @include mq(md) {
    padding-bottom: 4rem;
  }

  @include mq(xl) {
    padding-bottom: 6em;
  }
}

.spacing-bottom {
  @include cmmn_spacing_bottom;
}

.p-mgb-2 {
  p {
    margin-bottom: 1rem;

    @include mq(sm) {
      margin-bottom: 2rem;
    }
  }
}

.header-height {
  height: 86px;

  @include mq(sm) {
    height: 107px;
  }
}

@for $i from 0 through 5 {
  .letter-spacing-#{$i} {
    letter-spacing: #{$i}px;
  }
}