.bk-catering {
    height: 100%;

    a {
        display: block;
        height: 100%;
        padding-bottom: 2rem;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 3px;
            background-color: $primary-color;
            transition: all 0.2s ease;
            opacity: 0;
        }

        &:hover {
            &::after {
                opacity: 1;
            }
        }
    }

    .title {
        font-family: $secondary-family;
        color: $primary-color;
        font-size: 1.5rem;
        flex: 0 0 calc(100% - 6rem);
        max-width: calc(100% - 6rem);
        margin-bottom: 0;

        @include mq(sm) {
            font-size: 1.6rem;
        }

        @include mq(lg) {
            font-size: 1.8rem;
        }

        @include mq(xl) {
            font-size: 2rem;
        }
    }

    .price {
        font-size: 1.3rem;
        font-weight: 300;
        text-align: right;
        flex: 0 0 6rem;
        max-width: 6rem;

        @include mq(xl) {
            font-size: 1.5rem;
        }
    }

    p {
        margin-bottom: 0;
    }
}