
.arrow-center{
  .owl-nav{
    button{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      outline: 0;
    }

    .owl-prev{
      left: -6rem;
    }

    .owl-next{
      right: -6rem;
    }

    @include mq(mxl){
      .owl-prev{
        left: 0rem;
      }

      .owl-next{
        right: 0rem;
      }
    }
  }
}
